'use client';

import AccessHeader from '@/ui/components/access-header/access-header';

import { ROUTES } from '@/shared/routes';

export default function SignInLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <>
      <AccessHeader redirectFallback={ROUTES.SIGN_IN.INDEX} />
      {children}
    </>
  );
}
