'use client';

import { ArrowLeft, XIcon } from 'lucide-react';
import { usePathname } from 'next/navigation';
import React from 'react';

import { useSuppportModal } from '@/features/support/modal';

import { BaseHeader, HeaderLogo } from '@/ui/components/header';

import { useMediaQuery, useSearchParams } from '@/shared/hooks';
import { ROUTES } from '@/shared/routes';

import { Link, useRouter } from '@/lib/navigation';
import { cn } from '@/lib/utils';

import { Button } from '../button';

const AccessHeader = ({
  children,
  redirectFallback,
}: {
  children?: React.ReactNode;
  redirectFallback: string;
}) => {
  const { toggleSupportModal } = useSuppportModal();
  const pathname = usePathname();
  const isDesktop = useMediaQuery('md');
  const router = useRouter();
  const { getParam } = useSearchParams();
  const fallback = getParam('fallback');

  const handleClose = () => {
    if (pathname !== decodeURIComponent(fallback ?? '')) {
      return router.push(getParam('fallback') ?? redirectFallback);
    }
    return router.push(redirectFallback);
  };

  const hasBackButton = [
    ROUTES.SIGN_IN.PASSCODE_VERIFICATION as string,
    ROUTES.PASSCODE_RECOVERY.CONFIRM_PASSCODE as string,
  ].includes(pathname);

  const hasCancelButton = [
    ROUTES.PASSCODE_RECOVERY.CREATE_PASSCODE as string,
    ROUTES.PASSCODE_RECOVERY.INDEX as string,
    ...Object.values(ROUTES.TWO_FA),
  ].includes(pathname);

  const isSignInPage = pathname === ROUTES.SIGN_IN.INDEX;

  const flowCanBeInterrupted = hasCancelButton || hasBackButton;

  const showLogo = (isDesktop && flowCanBeInterrupted) || isSignInPage;

  return (
    <BaseHeader
      className={cn({
        'border-0': !isDesktop,
        'flex-row-reverse': flowCanBeInterrupted,
      })}
    >
      {isDesktop && flowCanBeInterrupted && (
        <span className="w-[36px] h-[36px]"></span>
      )}

      {showLogo ? (
        <HeaderLogo />
      ) : (
        <Button
          onClick={toggleSupportModal}
          size="tiny"
          variant="ghost"
          appearance="primary"
          className={{ root: 'rounded-full' }}
        >
          Need help?
        </Button>
      )}

      {children}

      {isSignInPage && (
        <Button
          as={Link}
          href={'/signup'}
          aria-label="Sign up"
          variant="outline"
          size="small"
          appearance="plain"
        >
          Sign up
        </Button>
      )}

      {hasBackButton && (
        <Button
          aria-label="Go Back"
          onClick={router.back}
          variant={isDesktop ? 'circle' : 'icon'}
          appearance="plain"
        >
          <ArrowLeft
            strokeWidth={2}
            className="w-units-unit20 h-units-unit20"
          />
        </Button>
      )}

      {hasCancelButton && (
        <Button
          aria-label="Close"
          onClick={handleClose}
          variant={isDesktop ? 'circle' : 'icon'}
          appearance="plain"
        >
          <XIcon strokeWidth={2} className="w-units-unit20 h-units-unit20" />
        </Button>
      )}
    </BaseHeader>
  );
};

export default AccessHeader;
